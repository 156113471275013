import { default as React, useMemo } from "react"
import connectStats from "instantsearch.js/es/connectors/stats/connectStats"
import algoliasearch from "algoliasearch/lite"
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  PoweredBy,
  RefinementList,
  useConnector,
  useInstantSearch,
  usePagination,
  useSearchBox,
} from "react-instantsearch-hooks-web"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Pagination as MaterialPagination,
  Stack,
  Typography,
} from "@mui/material"
import Seo from "../components/seo"
import Hit from "../components/component-hit"
import CustomSearchBox from "../components/custom-search-box"
import Pagination from "../components/pagination"

import { Search, FilterAlt, Tune, Close } from "@mui/icons-material"

function useStats(props) {
  return useConnector(connectStats, props)
}

function Stats(props) {
  const { status } = useInstantSearch();
  const { nbHits, query, nbPages, page } = useStats(props)
  const title = props.title.toLowerCase()
  const initialUiState = props.initialUiState || {}
  const isCategoryPage = Object.keys(initialUiState).length > 0
  const naturalPageNumber = page + 1 // page is zero indexed

  if (status === 'stalled') {
    return null
  }

  return (
    <Typography gutterBottom variant="h6" component={isCategoryPage ? "h2": "div"}>
      {nbHits} {title}
      {query && !isCategoryPage ? ` for "${query}"` : ""}
      {nbPages > 1 ? ` - Page ${naturalPageNumber} of ${nbPages}` : ''}
    </Typography>
  )
}

export default function SearchDesserts({ pageContext }) {
  const category = pageContext.category
  const title = pageContext.title ? pageContext.title : "Dessert Component Recipes"
  const uiState = pageContext.initialUiState
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const initialUiState = { "dev_QUENELLEGANT_DESSERT_COMPONENTS": uiState }

  const [filterDrawerOpen, setFilterDrawerOpen] = React.useState(false)

  const handleFilterClick = () => {
    setFilterDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setFilterDrawerOpen(false)
  }

  return (
    <>
      <Seo title={title} />
      <InstantSearch
        searchClient={searchClient}
        indexName="dev_QUENELLEGANT_DESSERT_COMPONENTS"
        routing={true}
        initialUiState={initialUiState}
      >
        {category && <Configure filters={`categories: '${category}'`} />}
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item sm={3}>
              <Typography component="h2" variant="h5">
                {title}
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={9} lg={6}>
            <CustomSearchBox />
          </Grid>

          <Grid item xs={12} sm={0} justifyContent="center" sx={{ display: { xs: 'flex', sm: 'none', lg: 'none' }}}>
            <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' }}}>
              <Button variant="outlined" size="small" startIcon={<Tune />} onClick={handleFilterClick}>Filters</Button>
              <Drawer
                anchor="top"
                open={filterDrawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{ display: { sm: 'none' }}}
              >
                <Stack spacing={4} p={4}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6" sx={{ display: "inline-flex", verticalAlign: "middle", alignItems: "center" }}>
                      <Tune />
                      Filters
                    </Typography>
                    <Typography onClick={handleDrawerClose} variant="subtitle1" sx={{ display: "inline-flex", verticalAlign: "middle", alignItems: "center" }}>
                      Close
                      <Close />
                    </Typography>
                  </Stack>

                  <Stack spacing={2} mb={2}>
                    <Typography variant="subtitle2">Ingredients</Typography>
                    <RefinementList
                      attribute="ingredients"
                      searchable={true}
                      operator="and"
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        searchBox: "refinement-list-search-box",
                      }}
                    />
                  </Stack>

                  <Stack spacing={2} mb={2}>
                    <Typography variant="subtitle2">Dessert Type</Typography>
                    <RefinementList
                      attribute="parent_recipe_application"
                      showMore={true}
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        showMore: "refinement-list-show-more",
                        disabledShowMore: "refinement-list-disabled-show-more",
                      }}
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Typography variant="subtitle2">Site</Typography>
                    <RefinementList
                      attribute="site_name"
                      showMore={true}
                      classNames={{
                        list: "refinement-list",
                        item: "refinement-list-item",
                        count: "refinement-list-count",
                        labelText: "refinement-list-label-text",
                        showMore: "refinement-list-show-more",
                        disabledShowMore: "refinement-list-disabled-show-more",
                      }}
                    />
                  </Stack>
                </Stack>
              </Drawer>
            </Box>
          </Grid>

          <Hidden lgDown>
            <Grid item sm={3}></Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item sm={3}>
              <Stack spacing={4}>
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                    alignItems: "center",
                  }}
                >
                  <FilterAlt sx={{ marginRight: "8px" }} />
                  Filters
                </Typography>

                <Stack spacing={2} mb={2}>
                  <Typography variant="subtitle2">Ingredients</Typography>
                  <RefinementList
                    attribute="ingredients"
                    searchable={true}
                    classNames={{
                      list: "refinement-list",
                      item: "refinement-list-item",
                      count: "refinement-list-count",
                      labelText: "refinement-list-label-text",
                      searchBox: "refinement-list-search-box",
                      searchBoxSubmit: "refinement-list-search-box-submit"
                    }}
                  />
                </Stack>

                <Stack spacing={2} mb={2}>
                  <Typography variant="subtitle2">Application</Typography>
                  <RefinementList
                    attribute="parent_recipe_application"
                    showMore={true}
                    classNames={{
                      list: "refinement-list",
                      item: "refinement-list-item",
                      count: "refinement-list-count",
                      labelText: "refinement-list-label-text",
                      showMore: "refinement-list-show-more",
                      disabledShowMore: "refinement-list-disabled-show-more",
                    }}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography variant="subtitle2">Site</Typography>
                  <RefinementList
                    attribute="site_name"
                    showMore={true}
                    classNames={{
                      list: "refinement-list",
                      item: "refinement-list-item",
                      count: "refinement-list-count",
                      labelText: "refinement-list-label-text",
                      showMore: "refinement-list-show-more",
                      disabledShowMore: "refinement-list-disabled-show-more",
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={9} lg={6}>
            <Stats title={title} initialUiState={pageContext.initialUiState}/>
            <Hits
              hitComponent={Hit}
              classNames={{
                list: "search-result-ol",
                item: "search-result-li",
              }}
            />
            <Pagination />
            <Grid item xs={3}>
              <PoweredBy />
            </Grid>
          </Grid>
        </Grid>
      </InstantSearch>
    </>
  )
}
