import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {Highlight} from "react-instantsearch-hooks-web";
import {default as React} from "react";

const Hit = ({ hit }) => (
  <Card>
    <CardActionArea href={hit.url} target="_blank">
      <CardMedia
        component="img"
        height="340"
        image={hit.hero_image_src}
        alt={hit.title + " Image"}
        sx={{ marginBottom: "0px" }}
      />

      <CardContent>
        <Typography gutterBottom variant="subtitle1" component="div">
          <Highlight attribute="site_name" hit={hit} tagname="mark" />
        </Typography>

        <Typography gutterBottom variant="h5" component="div">
          <Highlight attribute="title" hit={hit} tagname="mark" />
        </Typography>

        {hit.parent_recipe_name && (
          <Typography gutterBottom variant="subtitle1" component="div">
            From recipe: <b>{hit.parent_recipe_name}</b>
          </Typography>
        )}

        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            <Highlight attribute="categories" hit={hit} tagname="mark" />
          </Typography>
        </Box>

        {hit.components && (
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary">
              Components:{" "}
              <Highlight attribute="components" hit={hit} tagname="mark" />
            </Typography>
          </Box>
        )}

        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            Ingredients:{" "}
            <Highlight attribute="ingredients" hit={hit} tagname="mark" />
          </Typography>
        </Box>
      </CardContent>
    </CardActionArea>
  </Card>
)

export default Hit
